.handle {
    position: fixed;
    z-index : 2;
}

:not(p):not(ul):not(ol) > li {
    display: block;
}

.control {
    position:fixed; bottom:0px; left:calc(50vw - var(--column) / 2);
    z-index:8;
    background:#CCC;
    outline:0.5px solid black;
    border:2px outset #DDD;
    color:black;
    width:var(--column);
}

.handle [role="switch"] {
    padding   : 2px;
    background: grey;
    display   : block;
    display:none;
    color     : white;
    >.face {
        display: block;
    }
    &[aria-checked="true"]{
        >.face {
            border    : 2px groove darkblue;
            background: darkblue;
        }
        &:hover>face{
            border-style    : ridge;
        }
    }
    &[aria-checked="false"] {
        >.face {
            border    : 2px outset #888;
            background: #777;
        }
        &:hover>.face {
            background: darkblue;
        }
    }
}