.handle {
  z-index: 2;
  position: fixed;
}

:not(p):not(ul):not(ol) > li {
  display: block;
}

.control {
  bottom: 0;
  left: calc(50vw - var(--column) / 2);
  z-index: 8;
  color: #000;
  width: var(--column);
  background: #ccc;
  border: 2px outset #ddd;
  outline: .5px solid #000;
  position: fixed;
}

.handle [role="switch"] {
  color: #fff;
  background: gray;
  padding: 2px;
  display: none;
}

.handle [role="switch"] > .face {
  display: block;
}

.handle [role="switch"][aria-checked="true"] > .face {
  background: #00008b;
  border: 2px groove #00008b;
}

.handle [role="switch"][aria-checked="true"]:hover > face {
  border-style: ridge;
}

.handle [role="switch"][aria-checked="false"] > .face {
  background: #777;
  border: 2px outset #888;
}

.handle [role="switch"][aria-checked="false"]:hover > .face {
  background: #00008b;
}

/*# sourceMappingURL=index.5e2efc67.css.map */
